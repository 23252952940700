/* eslint-disable no-undef */
import Cookies from "js-cookie";

function loadScript(url) {
  return new Promise((res, rej) => {
    let script = document.createElement('script')
    script.src = url
    script.onload = res
    document.body.append(script)
  })
}

const recaptchaSiteKey = Cookies.get("recaptcha");

if (recaptchaSiteKey) {
  let recaptchaLoaded = loadScript(`https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`)
  let recaptchaReady = recaptchaLoaded.then(_ =>
    new Promise((res, rej) => grecaptcha.ready(res)))

  recaptchaReady.then(_ =>
    document.querySelector(".grecaptcha-badge").style.visibility = "collapse")

  document.addEventListener('submit', e => {
    let form = e.target;
    if (form.method.toUpperCase() !== 'POST') return
    let data = new FormData(form)
    if (data.get('recaptcha')) return

    e.preventDefault()
    recaptchaReady.then(_ => {
      grecaptcha
        .execute(recaptchaSiteKey)
        .then(token => {
          let input = document.createElement('input')
          input.name = 'recaptcha'
          input.type = 'hidden'
          input.value = token
          form.append(input)
          form.requestSubmit()
        })
    })
  })
}
